<template>
  <!-- 总决赛 -->
  <div class="bigScreen">
    <ScaleBox>
      <div class="main-wraper">
        <div class="contentBox">
          <span class="box1"></span>
          <span class="box2"></span>
          <span class="box3"></span>
          <span class="box4"></span>
          <div class="tableBox">
            <div class="titleBox">
              <div class="logoBox"></div>
              <div class="midBox">
                <div class="midLeftBox">
                  <p class="topBox">{{ groupData.comName }}</p>
                  <p class="buttomBox">{{ groupData.comType }}</p>
                </div>
                <div class="midRightBox">{{ groupData.comStartTime }}</div>
              </div>
              <div class="rightBox">
                <span class="sjBox"></span>
                总决赛
              </div>
            </div>
            <div class="tableConBox">
              <div class="topBox">
                <div class="leftBox">
                  <div>{{ player1.playerName }}</div>
                  <div>{{ player1.company }}</div>
                </div>
                <div class="midBox">
                  <div class="leBox"></div>
                  <div></div>
                </div>
                <div class="rightBox">
                  <div>{{ player2.playerName }}</div>
                  <div>{{ player2.company }}</div>
                </div>
              </div>
              <div class="botBox">
                <div class="leftBox">
                  <div class="conBox">
                    <div class="titBox">
                      <div>发序</div>
                      <div>环值</div>
                      <div>方向</div>
                    </div>
                    <div class="contBox">
                      <div
                        class="rowBox"
                        v-for="(item, index) in playerData1"
                        :key="index"
                      >
                        <div>{{ item.shootCount }}</div>
                        <div>{{ item.ringValue }}</div>
                        <div>
                          <i
                            v-if="item.direction === 1"
                            class="el-icon-top"
                          ></i>
                          <i
                            v-if="item.direction === 2"
                            class="el-icon-bottom"
                          ></i>
                          <i
                            v-if="item.direction === 3"
                            class="el-icon-back"
                          ></i>
                          <i
                            v-if="item.direction === 4"
                            class="el-icon-right"
                          ></i>
                          <i
                            v-if="item.direction === 5"
                            class="el-icon-top-left"
                          ></i>
                          <i
                            v-if="item.direction === 6"
                            class="el-icon-top-right"
                          ></i>
                          <i
                            v-if="item.direction === 7"
                            class="el-icon-bottom-right"
                          ></i>
                          <i
                            v-if="item.direction === 8"
                            class="el-icon-bottom-left"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="midBox">
                  <div class="conBox">
                    <div class="lBox">{{ player1.score }}</div>
                    <div class="mBox">
                      <div class="borBox"></div>
                      <div class="vsBox">vs</div>
                      <div class="borBox"></div>
                    </div>
                    <div class="rBox">{{ player2.score }}</div>
                  </div>
                </div>
                <div class="rightBox">
                  <div class="conBox">
                    <div class="titBox">
                      <div>发序</div>
                      <div>环值</div>
                      <div>方向</div>
                    </div>
                    <div class="contBox">
                      <div
                        class="rowBox"
                        v-for="(item, index) in playerData2"
                        :key="index"
                      >
                        <div>{{ item.shootCount }}</div>
                        <div>{{ item.ringValue }}</div>
                        <div>
                          <i
                            v-if="item.direction === 1"
                            class="el-icon-top"
                          ></i>
                          <i
                            v-if="item.direction === 2"
                            class="el-icon-bottom"
                          ></i>
                          <i
                            v-if="item.direction === 3"
                            class="el-icon-back"
                          ></i>
                          <i
                            v-if="item.direction === 4"
                            class="el-icon-right"
                          ></i>
                          <i
                            v-if="item.direction === 5"
                            class="el-icon-top-left"
                          ></i>
                          <i
                            v-if="item.direction === 6"
                            class="el-icon-top-right"
                          ></i>
                          <i
                            v-if="item.direction === 7"
                            class="el-icon-bottom-right"
                          ></i>
                          <i
                            v-if="item.direction === 8"
                            class="el-icon-bottom-left"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScaleBox>
  </div>
</template>

<script>
import { getSelectShootingByscheduleId, getSelectShootingByGroupName } from '../../api/realTime/index.js'
import ScaleBox from '../../components/ScaleBox/index.vue'
import webSocket from '@/api/socket.js'

export default {
  name: 'bigScreen',
  components: {
    ScaleBox
  },
  props: {},
  data() {
    return {
      scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
      groupData: {},
      groupNameData: {
        groupName: '',
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      },
      data1: [],
      playerData1: {},
      playerData2: {},
      player1: {},
      player2: {},
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {
    console.log(this.$route.query.scheduleId);
    sessionStorage.setItem('scheduleId', this.$route.query.scheduleId)
    sessionStorage.setItem('usertoken', this.$route.query.token)

  },
  created() {
    this.getSelectShootingByscheduleId();
  },
  mounted() {
    //自动刷新获取数据(1000*60*60=>毫秒*秒*分钟，此时表示为60分钟)
    this.refreshData = setInterval(() => {
      this.getSelectShootingByscheduleId();
    }, 1000 * 3);
  },
  //生命周期若已设置定时器，需清空定时器beforeDestroy()
  beforeDestroy() {
    // webSocket.close(res => {
    //   console.log("连接关闭", res);
    // })
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
  methods: {
    // 根据赛程id查询实时赛况信息
    async getSelectShootingByscheduleId() {
      const res = await getSelectShootingByscheduleId({ scheduleId: this.scheduleId });
      // console.log(res);
      if (res.status === 200) {
        this.groupData = res.data;
        if (res.data.groupNameList.length > 0) {
          this.groupNameData.groupName = res.data.groupNameList[0];
        }
        this.getSelectShootingByGroupName();
      }
    },
    // 查询靶位及实时分数
    async getSelectShootingByGroupName() {
      // this.data1 = [];
      // this.playerData1 = {};
      // this.playerData2 = {};
      const res = await getSelectShootingByGroupName(this.groupNameData);
      // console.log(res);
      if (res.status === 200) {
        this.data1 = res.data.targetList;
        this.playerData1 = res.data.championList1.shootingScoreList;
        this.playerData2 = res.data.championList2.shootingScoreList;
        this.player1 = res.data.championList1.player;
        this.player2 = res.data.championList2.player;
        this.$forceUpdate();
      }
      // console.log(this.playerData1);
      // console.log(this.playerData2);
    },

    //30秒后进入跳转页面
    threeGo() {
      const TIME_COUNT = 30;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.$router.push({
              path: './championshipList'
            });
          }
        }, 1000)
      }
    },

    // ----------------------------------------------------------
    // 设置表格第三列文字为左对齐
    // eslint-disable-next-line no-unused-vars
    cellStyle({ row, rowIndex }) {
      // 第n(3)列的时候，才改变
      // console.log(rowIndex);
      if (rowIndex === 0) {
        return `colStyle colStyle1`;
      } else if (rowIndex === 1) {
        return `colStyle colStyle2`;
      } else if (rowIndex === 2) {
        return `colStyle colStyle3`;
      } else {
        return `colStyle`;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bigScreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  background-image: url("../../assets/sjbj.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .main-wraper {
    position: relative;
    user-select: none;
    width: 1920px;
    height: 1080px;
    margin: 0 auto;
    .contentBox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      width: 1462px;
      height: 815px;
      // box-sizing: border-box;
      border: 3px solid #ffffff;
      .box1 {
        position: absolute;
        top: -3px;
        left: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-top: 3px solid #010945;
        border-left: 3px solid #010945;
      }
      .box2 {
        position: absolute;
        top: -3px;
        right: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-top: 3px solid #010945;
        border-right: 3px solid #010945;
      }
      .box3 {
        position: absolute;
        bottom: -3px;
        left: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-bottom: 3px solid #010945;
        border-left: 3px solid #010945;
      }
      .box4 {
        position: absolute;
        bottom: -3px;
        right: -3px;
        display: inline-block;
        width: 56px;
        height: 55px;
        border-bottom: 3px solid #010945;
        border-right: 3px solid #010945;
      }
      .tableBox {
        // box-sizing: border-box;
        // background: transparent;
        padding: 15px 16.5px;
        .titleBox {
          width: 1429px;
          height: 124px;
          display: flex;
          background: #010945;
          border: 3px solid #205090;
          box-sizing: border-box;
          .logoBox {
            width: 83px;
            height: 85px;
            margin: 22px 32px 17px 41px;
            background-image: url("../../assets/logo1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            // border: 1px dashed #fff;
          }
          .midBox {
            display: flex;
            width: 1079px;
            margin-top: 15px;
            // margin-right: 368px;
            .midLeftBox {
              width: 676px;
              margin-right: 169px;
              .topBox {
                font-size: 36px;
                font-family: MFJianHei_Noncommercial-Regular,
                  MFJianHei_Noncommercial;
                font-weight: 600;
                color: #ffffff;
                margin: 0;
              }
              .buttomBox {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                margin: 0;
              }
            }
            .midRightBox {
              width: 234px;
              font-size: 23px;
              font-family: MFJianHei_Noncommercial-Regular,
                MFJianHei_Noncommercial;
              font-weight: 400;
              text-align: right;
              color: #ffffff;
              line-height: 96px;
            }
          }
          .rightBox {
            position: relative;
            width: 222px;
            height: 114px;
            background: #cc834e;
            margin: 5px;
            font-size: 32px;
            font-family: MFJianHei_Noncommercial-Regular,
              MFJianHei_Noncommercial;
            font-weight: 400;
            color: #ffffff;
            line-height: 114px;
            text-align: center;
            .sjBox {
              position: absolute;
              top: 0;
              left: -1px;
              display: inline-block;
              /*宽高为0*/
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 114px 62px 0px 0px;
              border-color: #010945 transparent transparent transparent;
            }
          }
        }
        .tableConBox {
          width: 1429px;
          height: 661px;
          border: 3px solid #205090;
          box-sizing: border-box;
          .topBox {
            width: 1423px;
            height: 63px;
            display: flex;
            background: #010945;
            opacity: 0.8;
            .leftBox {
              width: 517px;
              display: flex;
              div {
                flex: 1;
                height: 63px;
                font-size: 26px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                text-align: center;
                line-height: 63px;
                letter-spacing: 0px;
              }
            }
            .midBox {
              width: 395px;
              display: flex;
              div {
                flex: 1;
                height: 63px;
              }
              .leBox {
                border-right: 3px solid #205090;
                box-sizing: border-box;
              }
            }
            .rightBox {
              width: 517px;
              display: flex;
              div {
                flex: 1;
                height: 63px;
                font-size: 26px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                text-align: center;
                line-height: 63px;
                letter-spacing: 0px;
              }
            }
          }
          .botBox {
            width: 1423px;
            height: 593px;
            display: flex;
            background: #ffffff;
            opacity: 0.8;
            box-sizing: border-box;
            .leftBox {
              width: 517px;
              .conBox {
                height: 588px;
                // background: #ffffff;
                // opacity: 0.6;
                .titBox {
                  width: 517px;
                  height: 47px;
                  display: flex;
                  border-bottom: 1px solid #c2c2c2;
                  box-sizing: border-box;
                  div {
                    width: 517px;
                    height: 47px;
                    flex: 1;
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    text-align: center;
                    color: #06206b;
                    line-height: 47px;
                    letter-spacing: 0px;
                  }
                }
                .contBox {
                  width: 517px;
                  height: 541px;
                  overflow: hidden;
                  .rowBox {
                    width: 517px;
                    height: 34px;
                    display: flex;
                    border-bottom: 1px solid #c2c2c2;
                    box-sizing: border-box;
                    div {
                      width: 517px;
                      height: 34px;
                      flex: 1;
                      font-size: 23px;
                      font-family: PingFangSC-Semibold, PingFang SC;
                      font-weight: 600;
                      text-align: center;
                      color: #06206b;
                      line-height: 34px;
                      letter-spacing: 0px;
                    }
                  }
                }
              }
            }
            .midBox {
              width: 395px;
              .topBox {
                width: 395px;
                height: 63px;
                display: flex;
                background: #010945;
                opacity: 0.8;
                div {
                  flex: 1;
                  border: none;
                }
                .lebox {
                  border-right: 1px solid #205090;
                }
              }
              .conBox {
                height: 588px;
                display: flex;
                // background: #ffffff;
                // opacity: 0.6;
                .lBox {
                  width: 150px;
                  height: 588px;
                  font-size: 100px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #06206b;
                  line-height: 588px;
                  // letter-spacing: 1px;
                  text-align: center;
                }
                .mBox {
                  flex: 1;
                  height: 528px;
                  margin-top: 30px;
                  .vsBox {
                    width: 48px;
                    height: 28px;
                    font-size: 28px;
                    font-family: huxiaobo-gdh, huxiaobo;
                    font-weight: normal;
                    text-align: center;
                    color: #06206b;
                    line-height: 28px;
                    // letter-spacing: 0px;
                    margin: 23px 0;
                    margin-left: 24px;
                  }
                  .borBox {
                    width: 1px;
                    height: 233px;
                    background-color: #979797;
                    margin-left: 47px;
                  }
                }
                .rBox {
                  width: 150px;
                  height: 588px;
                  font-size: 100px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #06206b;
                  line-height: 588px;
                  // letter-spacing: 1px;
                  text-align: center;
                }
              }
            }
            .rightBox {
              // width: 517px;
              .topBox {
                width: 517px;
                height: 63px;
                display: flex;
                background: #010945;
                opacity: 0.8;
                div {
                  flex: 1;
                  height: 63px;
                  font-size: 26px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #ffffff;
                  text-align: center;
                  line-height: 63px;
                  // letter-spacing: 0px;
                }
              }
              .conBox {
                height: 588px;
                // background: #ffffff;
                // opacity: 0.6;
                .titBox {
                  width: 517px;
                  height: 47px;
                  display: flex;
                  border-bottom: 1px solid #c2c2c2;
                  box-sizing: border-box;
                  div {
                    width: 517px;
                    height: 47px;
                    flex: 1;
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    text-align: center;
                    color: #06206b;
                    line-height: 47px;
                    // letter-spacing: 0px;
                  }
                }
                .contBox {
                  width: 517px;
                  height: 541px;
                  overflow: hidden;
                  .rowBox {
                    width: 517px;
                    height: 34px;
                    display: flex;
                    border-bottom: 1px solid #c2c2c2;
                    box-sizing: border-box;
                    div {
                      width: 517px;
                      height: 34px;
                      flex: 1;
                      font-size: 23px;
                      font-family: PingFangSC-Semibold, PingFang SC;
                      font-weight: 600;
                      text-align: center;
                      color: #06206b;
                      line-height: 34px;
                      // letter-spacing: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
